export const PANES = {
    PANE_OFFER: 'offer',
    PANE_SIDEMARK: 'sidemark',
    PANE_PROJECT: 'project',
    PANE_SHIPPING: 'shipping',
    PANE_DELIVERY: 'delivery',
    PANE_PAYMENT: 'payment',
    PANE_REVIEW: 'review',
    PANE_SUMMARY: 'summary',
} as const;
export type Pane = (typeof PANES)[keyof typeof PANES];

export const STEPS = {
    STEP_SHIPPING: PANES.PANE_SHIPPING,
    STEP_DELIVERY: PANES.PANE_DELIVERY,
    STEP_PAYMENT: PANES.PANE_PAYMENT,
    STEP_REVIEW: PANES.PANE_REVIEW,
} as const;
export type Step = (typeof STEPS)[keyof typeof STEPS];

export const LABELS = {
    [PANES.PANE_SHIPPING]: 'Address',
    [PANES.PANE_DELIVERY]: 'Shipping',
    [PANES.PANE_PAYMENT]: 'Payment',
    [PANES.PANE_REVIEW]: 'Review',
} as const;

export const STATES = {
    STATE_NEW: 'new',
    STATE_EDIT: 'edit',
    STATE_SAVED: 'saved',
    STATE_VERIFY: 'verify',
} as const;
export type State = (typeof STATES)[keyof typeof STATES];

export const TABS = {
    TAB_CREDIT_CARD: 'creditCard',
    TAB_PAYPAL_ACCOUNT: 'payPalAccount',
    TAB_WIRE_TRANSFER: 'wireTransfer',
    TAB_CHECK: 'check',
    TAB_APPLE_PAY: 'applePay',
    TAB_BANK_ACCOUNT: 'bankAccount',
    TAB_KLARNA: 'klarna',
} as const;
export type Tab = (typeof TABS)[keyof typeof TABS];

export const ACTIONS = {
    ACTION_EDIT: 'edit',
    ACTION_DELETE: 'delete',
    ACTION_CANCEL: 'cancel',
} as const;

export const METHODS = {
    METHOD_CREDIT_CARD: 'creditCard',
    METHOD_PAYPAL_ACCOUNT: 'payPalAccount',
    METHOD_WIRE_TRANSFER: 'wireTransfer',
    METHOD_ON_PLATFORM_WIRE_TRANSFER: 'onPlatformWireTransfer',
    METHOD_CHECK: 'check',
    METHOD_APPLE_PAY: 'applePay',
    METHOD_ACH: 'ach',
    METHOD_KLARNA: 'klarna',
} as const;
export type Method = (typeof METHODS)[keyof typeof METHODS];

export const PAYMENTS = {
    TYPE_CREDIT_CARD: 'CreditCard',
    TYPE_PAYPAL_ACCOUNT: 'PayPalAccount',
    TYPE_WIRE_TRANSFER: 'WireTransfer',
    TYPE_CHECK: 'Check',
    TYPE_APPLE_PAY: 'ApplePay',
    TYPE_BANK_ACCOUNT: 'ACH',
    TYPE_KLARNA: 'Klarna',
} as const;

export const VARS = {
    RESERVE_AMOUNT: 1,
    MAX_OPEN_PINGS: 3,
    PING_INTERVAL_DURATION: 1000,
    MAX_APP_AD_VIEWS: 3,
    MAX_ITEM_PRE_QUOTES: 2,
    MAX_NOTE_LENGTH: 500,
    MAX_SIDEMARK_ERROR: 35,
    TRADE_REWARD_AMOUNT: 50,
    TRADE_SPEND_AMOUNT: 5000,
    QUANTITY_LIMIT: 500000,
} as const;

export const STORAGE = {
    PREFIX: 'trans',
    AUTO_MODAL_INFO: 'checkoutAutoModalInfo',
    AUTO_MODAL_OFFER_INFO: 'checkoutAutoOfferModalInfo',
    SURVEY_INFO: 'checkoutSurveyInfo',
    TRACK_CONFIRMATION: 'needsToTrackConfirmation',
    HAS_TRACKED_EMAIL: 'hasTrackedEmail',
    APP_AD_VIEW: 'checkoutAppAdViews',
    TRIGGER_TYPE: 'triggerType',
    GUEST_USER_TOKEN: 'guestUserToken',
} as const;

export const ERRORS = {
    ERROR_401: 401,
    ERROR_409: 409, // multiple requests are attempting to modify a transaction at the same time
    ERROR_412: 412,
    ERROR_429: 429,
    ERROR_500: 500,
    ERROR_USER_EXISTS: 'USER_EXISTS',
    ERROR_VAULT: 'VAULT',
    ERROR_THREE_DS: 'THREE_DS',
    ERROR_DENIED: 'DENIED',
    ERROR_EXPIRED: 'EXPIRED',
    ERROR_USER_CHANGED: 'USER_CHANGED',
    ERROR_ITEM_NOT_AVAILABLE: 'ITEM_NOT_AVAILABLE',
    ERROR_ITEM_QUANTITY_NOT_AVAILABLE: 'ITEM_QUANTITY_NOT_AVAILABLE',
    THREE_DS_REQUIRED: 'THREE_DS_REQUIRED',
    ACH_APPROVED_WITH_RISK: 'ACH_APPROVED_WITH_RISK',
    ACH_PROCESSOR_DECLINED: 'ACH_PROCESSOR_DECLINED',
    ACH_DECLINED: 'ACH_DECLINED',
    ACH_INVALID_TRANSACTION_DATA: 'ACH_INVALID_TRANSACTION_DATA',
    ACH_NO_DATA_FOUND: 'ACH_NO_DATA_FOUND',
    ACH_UNKNOWN_VERIFICATION_CODE: 'ACH_UNKNOWN_VERIFICATION_CODE',
    ACH_NON_US_COUNTRY: 'ACH_NON_US_COUNTRY',
    AUCTION_HAS_BID: 'AUCTION_HAS_BID',
    AUCTION_IS_PAUSED: 'AUCTION_IS_PAUSED',
    SHIPPING_ERROR: 'SHIPPING_ERROR',
    PAYMENT_METHOD_ERROR: 'PAYMENT_METHOD_ERROR',
    USER_MISMATCH: 'USER_MISMATCH',
} as const;
export type Error = (typeof ERRORS)[keyof typeof ERRORS];

export const EMAIL_ERRORS = {
    USER_EXISTS: 'USER_EXISTS',
    EMAIL_ONLY_USER_EXISTS: 'EMAIL_ONLY_USER_EXISTS',
    EMAIL_ERRORS_DEFAULT: 'EMAIL_ERRORS_DEFAULT',
    SOCIAL_ACCOUNT_ALREADY_EXISTS: 'SOCIAL_ACCOUNT_ALREADY_EXISTS',
    CREDENTIALS_ARE_LEAKED: 'CREDENTIALS_ARE_LEAKED',
    EMAIL_INVALID: 'EMAIL_INVALID',
} as const;

export const BANNER_TYPES = {
    NEXT_STEPS: 'NEXT_STEPS',
} as const;

export const CHECKOUT_REGISTRATION_TRIGGER = 'CHECKOUT' as const;

export const CHECKOUT_TRACKED_ERRORS_TRIGGER_COUNT = 2 as const;
