import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import serverVars from 'server-vars';
import { getBuyerId, getTrafficType } from 'dibs-cookie-jar';
import { addLoginHandlers } from 'dibs-buyer-layout/exports/loginHandlers';
import { getAbTestV2 } from 'dibs-ab-tests/exports/clientAbTestV2';

import { usePrerenderedCheckoutStore } from '../prerender/prerenderedCheckoutStoreReact';
import { getCheckoutTrigger } from './checkoutTrackingTyped';
import { useCheckoutServerVars } from '../components/shared/CheckoutServerVarsContext';

const DD_RUM = serverVars.get('DD_RUM');
const abTests = serverVars.get('abTestsV2');
const locale = serverVars.get('locale');

export const setAbTestContextProperties = (): void => {
    if (!DD_RUM) {
        return;
    }

    // track ab tests decisions
    try {
        for (const testName of Object.keys(abTests)) {
            const abTest = getAbTestV2(testName);
            if (abTest?.variant) {
                datadogRum.setGlobalContextProperty(testName, abTest.variant);
            }
        }
    } catch (e) {
        // do nothing
    }
};

export const setDatadogRumUserId = (): void => {
    if (!DD_RUM) {
        return;
    }

    const setUserId = (): void => {
        const userId = getBuyerId(document.cookie);
        if (userId) {
            datadogRum.setUser({
                id: userId,
            });
        }
        const trafficType = getTrafficType(document.cookie, new URL(window.location.href));
        if (trafficType) {
            datadogRum.setGlobalContextProperty('trafficType', trafficType);
        }
    };

    setUserId();
    addLoginHandlers(() => {
        setUserId();
    });
};

export const initDatadogRum = (): void => {
    if (!DD_RUM) {
        return;
    }

    datadogRum.init({
        applicationId: '89e9a510-3de5-4df0-8d4e-d460f880e9cb',
        clientToken: 'pubc2eadb012ee8437b00287cd23fafa737',
        site: 'datadoghq.com',
        env: DD_RUM.env,
        sessionSampleRate: 100,
        trackResources: true,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackFrustrations: true,
        actionNameAttribute: 'data-tn',
        defaultPrivacyLevel: 'mask-user-input',
        trackViewsManually: true,
    });

    datadogRum.startSessionReplayRecording();
};

export type UseDatadogRumParams = {
    itemId: string;
};

export const useDatadogRum = ({ itemId }: UseDatadogRumParams): void => {
    const { rootState } = usePrerenderedCheckoutStore();
    const { checkoutType } = useCheckoutServerVars();

    useEffect(() => {
        if (!DD_RUM) {
            return;
        }

        let renderingType: 'FASTER' | 'PRE_RENDERED' | null = null;
        if (rootState === 'NOT_INITIALIZED') {
            renderingType = 'FASTER';
        } else if (rootState === 'VISIBLE') {
            renderingType = 'PRE_RENDERED';
        }

        if (!renderingType) {
            return;
        }

        const isDatadogInitialized = !!datadogRum.getInternalContext();
        if (!isDatadogInitialized) {
            initDatadogRum();
        }

        setDatadogRumUserId();

        datadogRum.startView({
            name: 'checkout',
            service: DD_RUM.service,
            version: DD_RUM.version,
        });

        if (typeof window !== 'undefined') {
            (window as $TSFixMe).__DIBS_CHECKOUT_START_TIME__ = Date.now();
        }

        datadogRum.addAction('checkout_init');
        datadogRum.addTiming('checkout_init_t'); // weird naming scheme to match checkout_loaded_t
        datadogRum.setGlobalContextProperty('checkoutItemId', itemId);
        datadogRum.setGlobalContextProperty('checkoutRenderingType', renderingType);
        datadogRum.setGlobalContextProperty('checkoutType', checkoutType || 'unknown');
        datadogRum.setGlobalContextProperty('dibsLocale', locale);
        datadogRum.setGlobalContextProperty(
            'checkoutTriggerType',
            getCheckoutTrigger() || 'unknown'
        );
        setAbTestContextProperties();
    }, [rootState, itemId, checkoutType]);
};
